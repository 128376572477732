html,
body,
header,
.carousel {
  height: 60vh; }

body {
  background: #114861; }

.cyan-skin .gradient {
  background: linear-gradient(180deg, #114861 0%, #45b0c0 100%); }

@media (max-width: 740px) {
  html,
  body,
  header,
  .carousel {
    height: 100vh; } }

@media (min-width: 800px) and (max-width: 850px) {
  html,
  body,
  header,
  .carousel {
    height: 100vh; } }

@media (min-width: 800px) and (max-width: 850px) {
  .navbar:not(.top-nav-collapse) {
    background: #1C2331 !important; } }

.view, body, html {
  height: 100%; }

@media (max-width: 740px) {
  .full-page-intro {
    height: 1000px; } }

.carousel {
  height: 50%; }

.carousel .carousel-inner, .carousel .carousel-inner .active, .carousel .carousel-inner .carousel-item {
  height: 100%; }

@media (max-width: 776px) {
  .carousel {
    height: 100%; } }

.navbar {
  background-color: rgba(0, 0, 0, 0.3); }

.page-footer, .top-nav-collapse {
  background-color: #1C2331; }

@media only screen and (max-width: 768px) {
  .navbar {
    background-color: #1C2331; } }

.display-inline-block {
  display: inline-block; }

.font-1-5 {
  font-size: 1.5rem; }

.back-gray {
  background: grey;
  opacity: .2; }

.display-grid {
  display: grid; }

.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem; }

.project-card {
  display: grid;
  grid-template-rows: auto 1fr; }
  .project-card .card-body {
    display: grid;
    grid-template-rows: auto 60px; }

@media (max-width: 992px) {
  .cards {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 768px) {
  .cards {
    grid-template-columns: 1fr; } }

.navbar {
  opacity: .85; }
  .navbar .logo {
    max-width: 3rem; }

.carousel-40 {
  height: 40%;
  min-height: 310px; }

.carousel-100 {
  height: 100%;
  min-height: 310px; }

.err404 {
  font-size: 10rem; }

@media (max-width: 772px) {
  .carousel-40 {
    height: 100%; } }

h1 {
  font-size: 3rem; }
